import React from 'react'
export const Nav00DataSource = {
  wrapper: { className: 'header0 home-page-wrapper' },
  page: { className: 'home-page' },
  logo: {
    className: 'header0-logo',
    // children:
    //   'https://cbdata-index-1259417822.cos.ap-nanjing.myqcloud.com/%E7%BC%96%E7%BB%84.png'
    children: 'https://a.yunzhuxue.com/static/logo/cb_logo.png',
    text: '宸邦云助学'
  },
  Menu: {
    className: 'header0-menu',
    children: []
  },
  mobileMenu: { className: 'header0-mobile-menu' }
}
export const Banner00DataSource = {
  wrapper: { className: 'banner0 ldnxsst0hrs-editor_css' },
  textWrapper: { className: 'banner0-text-wrapper' },
  title: {
    className: 'banner0-title ldnxfv7giw-editor_css',
    // children: 'https://zos.alipayobjects.com/rmsportal/HqnZZjBjWRbjyMr.png'
    children: 'https://a.yunzhuxue.com/static/logo/cb_logo.png'
  },
  content: {
    className: 'banner0-content ldnxesfsmg-editor_css',
    children: (
      <span>
        {/* <p>宸邦信息技术有限公司</p> */}
        <p>宸邦云助学</p>
      </span>
    )
  },
  button: {
    className: 'banner0-button ldnxg9kf1b-editor_css',
    children: <span></span>
  }
}
export const Content10DataSource = {
  wrapper: { className: 'home-page-wrapper content1-wrapper' },
  OverPack: { className: 'home-page content1', playScale: 0.3 },
  imgWrapper: { className: 'content1-img', md: 10, xs: 24 },
  img: {
    children: 'https://a.yunzhuxue.com/static/logo/yunzhuxue_logo.png'
  },
  textWrapper: { className: 'content1-text', md: 14, xs: 24 },
  title: {
    className: 'content1-title',
    children: <span>宸邦云助学</span>
  },
  content: {
    className: 'content1-content',
    children: (
      <div>
        <p>
          宸邦云助学是宸邦教育大数据中重要的一环，包含了家校连接、校园通知、家委会、公益助学等功能。
        </p>
        <div
          className="download-btn"
          onClick={() => {
            window.location.href = 'https://www.pgyer.com/cbyzx'
          }}
        >
          立即下载
        </div>
      </div>
    )
  }
}
export const Content00DataSource = {
  wrapper: { className: 'home-page-wrapper content0-wrapper' },
  page: { className: 'home-page content0' },
  OverPack: { playScale: 0.3, className: '' },
  titleWrapper: {
    className: 'title-wrapper',
    children: [
      {
        name: 'title',
        children: (
          <span>
            <p>产品功能</p>
          </span>
        )
      }
    ]
  },
  childWrapper: {
    className: 'content0-block-wrapper',
    children: [
      {
        name: 'block0',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon ldnyctpecc8-editor_css',
              children:
                'https://a.yunzhuxue.com/static/app/yzx/image/intro_1.png'
            },
            {
              name: 'title',
              className: 'content0-block-title ldnycyv8mx-editor_css',
              children: '一站式业务接入'
            },
            {
              name: 'content',
              children: '支付、结算、核算接入产品效率翻四倍',
              className: 'ldnyd10vgo-editor_css'
            }
          ]
        }
      },
      {
        name: 'block1',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon ldnydyq43nb-editor_css',
              children:
                'https://a.yunzhuxue.com/static/app/yzx/image/intro_2.png'
            }
          ]
        }
      },
      {
        name: 'block2',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon ldnydyq43nb-editor_css',
              children:
                'https://a.yunzhuxue.com/static/app/yzx/image/intro_3.png'
            }
          ]
        }
      },
      {
        name: 'block3',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon ldnydyq43nb-editor_css',
              children:
                'https://a.yunzhuxue.com/static/app/yzx/image/intro_4.png'
            }
          ]
        }
      },
      {
        name: 'block4',
        className: 'content0-block',
        md: 8,
        xs: 24,
        children: {
          className: 'content0-block-item',
          children: [
            {
              name: 'image',
              className: 'content0-block-icon ldnydyq43nb-editor_css',
              children:
                'https://a.yunzhuxue.com/static/app/yzx/image/intro_5.png'
            }
          ]
        }
      }
    ]
  }
}
export const Footer00DataSource = {
  wrapper: { className: 'home-page-wrapper footer0-wrapper' },
  OverPack: { className: 'home-page footer0', playScale: 0.05 },
  copyright: {
    className: 'copyright',
    children: (
      <span>
        <span>
          <p>
            <span>
              ©2019-2023 &nbsp;宸邦信息技术有限公司 Chenbang-inc &nbsp;All
              Rights Reserved&nbsp; &nbsp; &nbsp; &nbsp;&nbsp;
            </span>
            <a href="https://beian.miit.gov.cn">浙ICP备19032270号-4</a>
          </p>
        </span>
      </span>
    )
  }
}
